.visible-gte-md,
.visible-lt-md,
.visible-gte-sm,
.visible-lt-sm {
    display: none !important;
}

.p-maia-viewport--up-sm,
.p-maia-viewport--sm {
    .visible-gte-sm {
        display: block !important;
    }
}

.p-maia-viewport--down-sm {
    .visible-lt-sm {
        display: block !important;
    }

    &.p-maia-viewport--sm {
        .visible-lt-sm {
            display: none !important;
        }
    }
}

.p-maia-viewport--up-md,
.p-maia-viewport--md {
    .visible-gte-md {
        display: block !important;
    }
}

.p-maia-viewport--down-md {
    .visible-lt-md {
        display: block !important;
    }

    &.p-maia-viewport--md {
        .visible-lt-md {
            display: none !important;
        }
    }
}
