.checkbox-list {
    > .maia-container > .maia-input {
        margin-left: 0 !important;
    }
    .check-all {
        margin-left: 21px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    maia-condition maia-checkbox {
        padding-top: 24px !important;
    }
}
